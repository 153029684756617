import { PropsWithChildren, type FunctionComponent } from 'react';
import Image from 'next/image';
import classes from './AuthLayout.module.css';

export interface AuthLayoutProps extends PropsWithChildren {}

const AuthLayout: FunctionComponent<AuthLayoutProps> = ({ children }) => {
  return (
    <div className={classes.root}>
      {/* eslint-disable-next-line react/forbid-elements -- This is a layout, therefore
          <main> is allowed. */}
      <main className={classes.column}>
        <header>
          {/* TODO: CRE-3214 source this logo from tenant config */}
          <Image
            priority={true}
            src="/brand/caire-dark.svg"
            alt="Caire"
            width={250}
            height={70}
          />
        </header>
        {children}
      </main>
    </div>
  );
};

export default AuthLayout;
